.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

body {
  background-color: #f0f2f5;
}

.site-layout-sub-header-background {
  color: white;
  background: #575756;
  padding: 20px;
  font-size: 18px;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}

.ant-modal-header {
  background-color: #575756;
  color: white;
}
.ant-modal-close-x {
  color: white;
}
.ant-modal-title {
  color: white;
}

.ant-form-item-label {
  padding: 0;
}

.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 12px;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}
.react-resizable-handle {
  position: absolute;
  right: -10px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.ant-input-number {
  width: 100%;
}

.ant-table-sticky-holder {
  z-index: 2 !important;
}

.ant-descriptions-header {
  padding: 10px;
  margin-bottom: 0;
}

.table-row-light {
  background-color: #eee;
}
.table-row-dark {
  background-color: #ddd;
  font-weight: bold;
  font-size: 16px;
}
.table-row-dark > .ant-table-cell-row-hover {
  background: #ddd !important;
}

.table-row-success {
  background-color: #8baf88;
  font-weight: bold;
  font-size: 16px;
}
.table-row-success > .ant-table-cell-row-hover {
  background: #aacfa6 !important;
}
